<template>
  <div class="mx-1">
    <section-support :sections="sourceSection" />
  </div>
</template>
<script>


import { mapGetters } from 'vuex'
import sourcedata from './data'
import SectionSupport from './SectionSupport.vue'
export default {
  components: { SectionSupport },
  data() {
    return {
      userData: {},
      sourceSection: []
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    }),
    isCenco() {
      return this.userData?.special_profile === 'cenco'
    },
    isAdmin() {
      return ['superadmin', 'admin'].includes(this.userData?.role)
    }

  },
  watch: {
    mySession() {
      if (this.mySession.id) this.setSourceSection()
    }
  },
  mounted() {
    if (this.mySession.id) this.setSourceSection()
  },
  methods: {
    setSourceSection() {
      this.userData = this.$session.get('cas_user')
      this.sourceSection = this.isAdmin ? this.getSectionsAdmin() : this.getSections()
    },
    getSections() {
      if (this.isCenco) {
        const cencoSection = []
        cencoSection.push(sourcedata.sections.find((section) => section.name === 'cenco'))
        cencoSection[0].name = ''
        return cencoSection
      } else {
        return sourcedata.sections.map((section) => {
          if (section.name !== 'cenco') {
            const haveCountry = section.items.some(item => 'country_id' in item)
            if (haveCountry) {
              const itemsByCountry = section.items.filter(item => item.country_id === this.userData?.country.id || item.country_id === 0)
              section.items = itemsByCountry
              return section
            }
            return section
          } else {
            return {}
          }
        }
        )
      }
    },
    getSectionsAdmin() {
      return sourcedata.sections.filter((section) => section.name !== 'cenco')
    }
  }
}
</script>
<style lang="scss"></style>