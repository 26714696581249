<template>
  <section class="container-sections">
    <div v-for="(section, index) in sectionsData" :key="index">
      <elements :section="section"></elements>
    </div>
  </section>
</template>
<script>
import elements from './Elements.vue'
export default {
  data() {
    return {
      sectionsData:[]
    }
  },
  props: {
    sections: Array
  },
  components: { elements },
  watch: {
    sections () {
      this.setInitialData()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: { 
    setInitialData() {
      this.sectionsData = this.sections
    }
  }
}
</script>
<style scoped>
.container-sections {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
}
</style>