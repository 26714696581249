<template>
  <div>
    <h3>{{ sectionInfo.name }}</h3>
    <div class="container-elements">
      <div v-for="(element, index) in sectionInfo.items" :key="index" :class="element.class">
        <feather-icon :icon="element.icon" size="60" color="white" />
        <div class="sub-element">
          <span>{{ element.text }}</span>
          <a :href="element.url" target="_blank"><feather-icon :icon="element.urlIcon" size="30" color="white" /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sectionInfo: {}
    }
  },
  props: {
    section: Object
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    section() {
      this.setInitialData()
    }
  },
  methods: {
    setInitialData() {
      this.sectionInfo = this.section
    }
  }


}
</script>
<style scoped>
.container-elements {
  display: grid;
  grid-template-columns: repeat(4, minmax(10vw, 20vw));
  grid-gap: 1rem 2rem;
}

.element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid #1c84c6;
  border-radius: 0.4rem;
  background-color: #1c84c6;
  width: 100%;
  height: 80%;
  margin: 0.5rem;
  padding: 1rem 1.5rem;
  transition: transform 0.6s;
  box-shadow: 1px 1px 20px 8px lightgray;
  text-align: right;
}

.element:hover {
  transform: translateY(-5px);
}

.sub-element {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.sub-element span {
  font-size: 0.8rem;
  color: white;
}

a>.feather:hover {
  color: #0d5da3;
}

.bg-orange {
  background-color: #f7901e;
  border-color: #f7901e;
}


@media screen and (max-width: 800px) {
  .container-elements {
    grid-template-columns: 1fr;
    grid-gap: 0.2rem;
  }

  .element {
    margin: 0;
    height: 95%;
    box-shadow: none;
    padding: 0.7rem 1rem;
  }
}
</style>