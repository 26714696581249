export default {
  sections: [
    {
      name: 'cenco',
      items: [
        {
          url: 'https://drive.google.com/drive/folders/1Eu4s7bUnVNGuB9n40TgJmpObgcYaRdf-',
          text: 'Manuales Envíame',
          icon: 'FileIcon',
          urlIcon: 'FolderIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/drive/folders/15Q5Bx6SDlUmUiLAJLd5ciKfd9Gg6NFDK',
          text: 'Video tutoriales de uso',
          icon: 'YoutubeIcon',
          urlIcon: 'PlayIcon',
          class: 'element'
        }
      ]
    },
    {
      name: '',
      items: [
        {
          url: 'https://drive.google.com/drive/folders/1s4IJjzoPbEM5T47D1DpIFo6-CNg5i7sT',
          text: 'Manuales Envíame',
          icon: 'FileIcon',
          urlIcon: 'FolderIcon',
          class: 'element',
          country_id: 0
        },
        {
          url: 'https://www.youtube.com/playlist?list=PLe33U1sB-MiI9BmeiTryFkuM68vCRo-kf',
          text: 'Video tutoriales de uso',
          icon: 'YoutubeIcon',
          urlIcon: 'PlayIcon',
          class: 'element',
          country_id: 0
        }
        // {
        //   url: 'https://docs.google.com/spreadsheets/d/1MrPR_eSqCoolJUHer-GnIYhy6s6qyNN5/edit?usp=sharing&ouid=113692028797710766861&rtpof=true&sd=true',
        //   text: 'Códigos Couriers Chile',
        //   icon: 'MailIcon',
        //   urlIcon: 'GlobeIcon',
        //   class: 'element',
        //   country_id: 1
        // },
        // {
        //   url: 'https://docs.google.com/spreadsheets/d/1Met8Raiy7Ri_uD1fWi8peI_onR6vTBp8/edit?usp=sharing&ouid=113692028797710766861&rtpof=true&sd=true',
        //   text: 'Códigos Couriers Colombia',
        //   icon: 'MailIcon',
        //   urlIcon: 'GlobeIcon',
        //   class: 'element',
        //   country_id: 3
        // },
        // {
        //   url: 'https://docs.google.com/spreadsheets/d/1Mn_MT625AMC84MCr1V3FLmBElkfTkv2e/edit?usp=sharing&ouid=113692028797710766861&rtpof=true&sd=true',
        //   text: 'Códigos Couriers Perú',
        //   icon: 'MailIcon',
        //   urlIcon: 'GlobeIcon',
        //   class: 'element',
        //   country_id: 4
        // },
        // {
        //   url: 'https://docs.google.com/spreadsheets/d/1Mv3Rdg-cyoOdTxai63gMmzZvpCvA1GIN/edit?usp=sharing&ouid=113692028797710766861&rtpof=true&sd=true',
        //   text: 'Códigos Couriers Mexico',
        //   icon: 'MailIcon',
        //   urlIcon: 'GlobeIcon',
        //   class: 'element',
        //   country_id: 2
        // }
      ]
    },
    {
      name: 'Resumen condiciones operadores',
      items: [
        {
          url: 'https://docs.google.com/spreadsheets/d/16wJB1VMO43TXRqwTztiI5kdM_Dx-UYS2/edit#gid=160234924',
          text: 'Resumen Couriers Chile',
          country_id: 1,
          icon: 'FileTextIcon',
          urlIcon: 'FolderIcon',
          class: 'element bg-orange'
        },
        {
          url: 'https://docs.google.com/spreadsheets/d/1iyS6qDpAjX8Dk7YV8_dsCDDrtdSj6PtI/edit#gid=2043107374',
          text: 'Resumen Couriers Colombia',
          country_id: 3,
          icon: 'FileTextIcon',
          urlIcon: 'FolderIcon',
          class: 'element bg-orange'
        },
        {
          url: 'https://docs.google.com/spreadsheets/d/1_ubmvNUckBNGi_dz6I0fArdxH30VQQ3j/edit?usp=drive_web&ouid=115517251684867822654&rtpof=true',
          text: 'Resumen Couriers Perú',
          country_id: 4,
          icon: 'FileTextIcon',
          urlIcon: 'FolderIcon',
          class: 'element bg-orange'
        },
        {
          url: 'https://docs.google.com/spreadsheets/d/1j-o58gJ__qASQHZ2kKlA9QGGjEvBhBRM/edit?usp=drive_web&ouid=115517251684867822654&rtpof=true',
          text: 'Resumen Couriers México',
          country_id: 2,
          icon: 'FileTextIcon',
          urlIcon: 'FolderIcon',
          class: 'element bg-orange'
        }
      ]
    },
    {
      name: 'Documentos e información',
      items: [
        {
          url: 'https://platform.enviame.io/doc/Contrato-Enviame-2022.pdf',
          text: 'Contrato Chile',
          country_id: 1,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://platform.enviame.io/doc/TYC-Colombia-Enviame-Enero2022.pdf',
          text: 'Contrato Colombia',
          country_id: 3,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://platform.enviame.io/doc/Contrato-Peru-Enviame.pdf',
          text: 'Contrato Perú',
          country_id: 4,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://platform.enviame.io/doc/Preguntas_frecuentes.pdf',
          text: 'Preguntas frecuentes',
          icon: 'HelpCircleIcon',
          urlIcon: 'DownloadIcon',
          country_id: 0,
          class: 'element'
        },
        {
          url: 'https://drive.google.com/drive/folders/1FwxMaKjEQvFKY3OtQLVlVwSS0SepR-Ox?usp=sharing',
          text: 'Indemnizaciones y Etapas del ticket Chile',
          country_id: 1,
          icon: 'HelpCircleIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/drive/folders/1zCc65ljGG_u7JYb8R98FguxyPZ5Pr4jk',
          text: 'Indemnizaciones y Etapas del ticket México',
          country_id: 2,
          icon: 'HelpCircleIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/drive/folders/1uYcfSFQclXKmlJA9a36PSj-cjO5o9Nd9?usp=sharing',
          text: 'Indemnizaciones y Etapas del ticket Colombia',
          country_id: 3,
          icon: 'HelpCircleIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/drive/folders/1acyLLP1vIV8dEQbAN2a3JqmW77l6GyU7?usp=sharing',
          text: 'Indemnizaciones y Etapas del ticket Perú',
          country_id: 4,
          icon: 'HelpCircleIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/file/d/14EidOvT6OtZEsf5l1B3HRAB05eZ7Hy-h/view',
          text: 'Instructivo de Pago Chile',
          country_id: 1,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/file/d/1xRDSzdap3PneKQREBjnJVLMJJEYdherv/view',
          text: 'Instructivo de Pago México',
          country_id: 2,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/file/d/1ttNCRCiKlMai5pUbk_VA-yDvtVoYxIoU/view',
          text: 'Instructivo de Pago Colombia',
          country_id: 3,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/file/d/1R30YjYHeUhSY2IFtN335DnSx0ibFMEIe/view',
          text: 'Instructivo de Pago Perú',
          country_id: 4,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/file/d/1NoGo_m0zy41wua81byOTsL_UhZoJtP2V/view?usp=drive_link',
          text: 'Condiciones Enviaflash Chile',
          country_id: 1,
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        },
        {
          url: 'https://drive.google.com/file/d/12TLLYr53ojwSdiyF9Wi70VzC3cqQiwJB/view?usp=sharing',
          text: 'Términos y condiciones Garantía de Entregas',
          icon: 'FileTextIcon',
          urlIcon: 'DownloadIcon',
          class: 'element'
        }
      ]
    }
  ]
}